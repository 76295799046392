@import url(https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap);
h1{text-align:center;margin-top:20px !important}.global_config{padding-top:30px}@media(min-width: 1200px){.container,.container-lg,.container-md,.container-sm,.container-xl{max-width:1440px !important}}.ask-column{color:#f0f}.bid-column{color:aqua}.ask-highlight,.bid-highlight{background-color:#7fff00;color:#000;height:100%;width:100%}.synth-highlight{color:#ff0}.last-price-hightlight{color:redl}.modal-dialog{max-width:95% !important}
.table-valuation td {
    padding: 0.75rem 0.5rem;
}

.series-header .offset {
    margin-top: 15px;
    width: 14%;
}

.series-header .iv-table {
    width: 28%;
}

.series-header .future-price {
    margin-top: 15px;
    width: 32%;
    display: flex;
}

.series-header .checkbox {
    width: 26%;
}

.ml-tiny {
    margin-left: 5.2px;
}

.custom_select__control {
    border-radius: 0px  4px 4px 0px!important;
    width: 100%;
}

.select-in-form-control {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
}

