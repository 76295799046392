.table-valuation td {
    padding: 0.75rem 0.5rem;
}

.series-header .offset {
    margin-top: 15px;
    width: 14%;
}

.series-header .iv-table {
    width: 28%;
}

.series-header .future-price {
    margin-top: 15px;
    width: 32%;
    display: flex;
}

.series-header .checkbox {
    width: 26%;
}

.ml-tiny {
    margin-left: 5.2px;
}

.custom_select__control {
    border-radius: 0px  4px 4px 0px!important;
    width: 100%;
}

.select-in-form-control {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
}
