@import url("https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap");

// @import "./node_modules/bootstrap/scss/functions";
// @import "./node_modules/bootstrap/scss/variables";
// @import "./node_modules/bootstrap/scss/mixins";
// @import "./node_modules/bootstrap/scss/grid";
// @import "./node_modules/bootstrap/scss/utilities";
// @import "./node_modules/bootstrap/scss/buttons";
// @import "./node_modules/bootstrap/scss/f";

// body {
//     background-color: #363537 !important;
//     color: #FAFAFA !important;
//   }

h1 {
    text-align: center;
    margin-top: 20px !important;
}

.global_config {
    padding-top: 30px;
}


@media (min-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1440px !important;
    }
}

.ask-column {
    color: magenta;
}
.bid-column {
    color: cyan;
}

.ask-highlight,
.bid-highlight {
    background-color: chartreuse;
    color: black;
    height: 100%;
    width: 100%;
    // font-weight: bold;
}

.synth-highlight {
    color: yellow;
}

.last-price-hightlight {
    color: redl;
}

.modal-dialog {
    max-width: 95% !important;
}
